import React, { useEffect, useState } from "react";
import ogimage from "../assets/Images/ogImages/OG_DigirytePods.jpg";

import { BASE_SITE_URL } from "../shared/constants";
import HeroTabSection from "../components/HeroTabSection";
import Layout from "../components/layouts/layout";
import FormSection from "../components/FormSection";
import RevealAnimation from "../components/RevealAnimation";
import LocationCards from "../templates/OurLocation";

import mapImage1 from "../assets/Images/mobileView/Our_Locations/OurLocation.png";
import mChennai from "../assets/Images/mobileView/Our_Locations/Chennai.png";
import mBangalore from "../assets/Images/mobileView/Our_Locations/bangalore.png";
import mCoimbatore from "../assets/Images/mobileView/Our_Locations/Coimbatore.png";
import mHyderabad from "../assets/Images/mobileView/Our_Locations/Hyderabad.png";
import Manchester from "../assets/Images/mobileView/Our_Locations/Manchester.png";
import mMumbai from "../assets/Images/mobileView/Our_Locations/Mumbai.png";
import mapImage from "../assets/Images/Homepage/Locations/location.png";
import Banglore from "../assets/Images/Homepage/Locations/Banglore.png";
import Chennai from "../assets/Images/Homepage/Locations/Chennai.png";
import Coimbatore from "../assets/Images/Homepage/Locations/Coimbatore.png";
import Hyderabad from "../assets/Images/Homepage/Locations/Hyderabad.png";
import Mumbai from "../assets/Images/Homepage/Locations/Mumbai.png";
import UK from "../assets/Images/Homepage/Locations/UK.png";
import Infoview from "../templates/InfoView";

const DigirytePods = () => {
  const LocationCardData = {
    mapImage: mapImage,
    locationImages: [
      {
        image: UK,
        imgTitle: (
          <>
            Manchester <br /> (HQ)
          </>
        ),
        route: "/locations/manchestor",
      },
      {
        image: Banglore,
        imgTitle: "Bangalore",
        route: "/locations/bangalore",
      },
      {
        image: Hyderabad,
        imgTitle: "Hyderabad",
        route: "/locations/hyderabad",
      },
      {
        image: Mumbai,
        imgTitle: "Pune",
        route: "/locations/mumbai",
      },
      {
        image: Chennai,
        imgTitle: "Chennai",
        route: "/locations/chennai",
      },
      {
        image: Coimbatore,
        imgTitle: "Coimbatore",
        route: "/locations/coimbatore",
      },
    ],
  };

  const LocationCardData1 = {
    mapImage: mapImage1,
    locationImages: [
      {
        image: Manchester,
        imgTitle: (
          <>
            Manchester <br /> (HQ)
          </>
        ),
        route: "/locations/manchestor",
      },
      {
        image: mBangalore,
        imgTitle: "Bangalore",
        route: "/locations/bangalore",
      },
      {
        image: mHyderabad,
        imgTitle: "Hyderabad",
        route: "/locations/hyderabad",
      },
      {
        image: mMumbai,
        imgTitle: "Mumbai",
        route: "/locations/mumbai",
      },
      {
        image: mChennai,
        imgTitle: "Chennai",
        route: "/locations/chennai",
      },
      {
        image: mCoimbatore,
        imgTitle: "Coimbatore",
        route: "/locations/coimbatore",
      },
    ],
  };

  const InfoviewData = {
    InfoviewTitle: (
      <div className="ttc">
        {" "}
        <span className="red-line3letter red-3letter-mbl">Our</span> Locations
      </div>
    ),
    InfoviewContent: (
      <>
        All our senior management team, tech consultants, client directors and
        account managers are all based in the UK. Our development team, which
        consists of the top 5% of the premium professionals make up our offshore
        talent. We ensure that all data for any project is stored within the UK
        borders only and access to development team is only on an on need basis.
      </>
    ),
  };

  return (
    <Layout
      seo={{
        title: "Our Pod Model | Digiryte",
        ogtitle: "Our Pod Model | Digiryte",
        description:
          "Digiryte assists in hiring, managing, and implementing all the relevant human resource functions for building and scaling reliable and dynamic remote teams.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/digiryte-pods`,
        keywords:
          "remote teams, hiring, managing, human resources, scaling teams, dynamic teams",
      }}
    >
      <HeroTabSection />
      <div className="mt-70">
        <FormSection />
      </div>
      <div className="common-bg-light mt-70">
        <RevealAnimation animation="zoom-in" className="">
          <Infoview InfoviewData={InfoviewData} />
        </RevealAnimation>
        <div className="hide-in-mobile">
          <LocationCards
            mapImage={mapImage}
            label="View Location"
            LocationCardData={LocationCardData}
          />
        </div>
        <div className="hide-in-web">
          <LocationCards
            mapImage={mapImage1}
            label="View Location"
            LocationCardData={LocationCardData1}
          />
        </div>
      </div>
    </Layout>
  );
};

export default DigirytePods;
