import React, { useEffect, useState } from "react";
import "./style.scss";

import RevealAnimation from "../RevealAnimation";
import LeftIcon from "../../assets/Images/PodModel/we-handle-operation/slide/left.svg";
import RightIcon from "../../assets/Images/PodModel/we-handle-operation/slide/right.svg";

function SliderSection({
  listObjectData = [],
  wrapClassName = "",
  hideController = false,
  imageContainerClass = "",
}) {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === listObjectData.length - 1 ? 0 : prevSlide + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [hideController, listObjectData.length]);

  const handleNext = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === listObjectData.length - 1 ? 0 : prevSlide + 1
    );
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? listObjectData.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className={`sliderSectionWrap ${wrapClassName}`}>
      <div className="sliderContainer body-container">
        {listObjectData?.map((slider, index) => (
          <div
            key={index}
            className={`sliderInnerWrap ${
              index === currentSlide ? "active" : ""
            }`}
          >
            <div className="leftSectionWrap">
              <RevealAnimation
                component={"h2"}
                animation={hideController ? "none" : "slide-right"}
                className="title-header title-underline"
              >
                {slider.title}
              </RevealAnimation>
              <RevealAnimation
                component={"p"}
                animation={hideController ? "none" : "slide-right"}
              >
                {slider.description}
              </RevealAnimation>
            </div>
            <div className="rightSectionWrap">
              <RevealAnimation
                component={"div"}
                animation={"slide-left"}
                className={`image-container ${imageContainerClass}`}
              >
                <img
                  className={`slide-image`}
                  src={slider?.imageUrl}
                  alt={""}
                />
              </RevealAnimation>
            </div>
          </div>
        ))}
        <div
          className={`controller-wrap ${hideController ? "hidden-track" : ""}`}
        >
          {!hideController && (
            <div className="track_layout">
              {listObjectData.map((_, inKey) => (
                <div
                  key={inKey}
                  className={`track ${inKey === currentSlide ? "active" : ""}`}
                  onClick={() => {
                    setCurrentSlide(inKey);
                  }}
                />
              ))}
            </div>
          )}
          <div className="controller_nav">
            <div className="nav prev_nav" onClick={handlePrev}>
              <img className="" src={LeftIcon} alt="Previous" />
            </div>
            <div className="nav next_nav" onClick={handleNext}>
              <img className="" src={RightIcon} alt="Next" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SliderSection;
