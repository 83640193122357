import React from "react";
import "./style.scss";
import RevealAnimation from "../RevealAnimation";

function CardWithIcon({ data, wrapClassName = "" }) {
  return (
    <div className={`CardWithIconWrap ${wrapClassName}`}>
      {data.map((item, index) => (
        <div key={index} className="CardWithIconContainer">
          <RevealAnimation className="img-wrap">
            <img src={item.iconUrl} alt="" />
          </RevealAnimation>
          <RevealAnimation className="title">{item.title}</RevealAnimation>
        </div>
      ))}
    </div>
  );
}

export default CardWithIcon;
