import React from "react";
import "./style.scss";
import RevealAnimation from "../RevealAnimation";
function CardDetailsWithIcon({
  heading = "",
  title = "",
  icon = "",
  description = "",
  wrapClassName = "",
  containerClassName = "",
}) {
  return (
    <div className={`card-details-icon-wrap ${wrapClassName}`}>
      <div className={`body-container ${containerClassName}`}>
        <div className="card-details-icon-inner-wrap">
          <RevealAnimation component={"p"} className="headline">
            {heading}
          </RevealAnimation>
          <RevealAnimation className="card-details-icon-container">
            <RevealAnimation className="card-img-wrap">
              <img src={icon} alt={title} />
            </RevealAnimation>
            <div className="card-content">
              <p className="title">{title}</p>
              <p className="description">{description}</p>
            </div>
          </RevealAnimation>
        </div>
      </div>
      <div className="card-details-icon-overlay"></div>
    </div>
  );
}

export default CardDetailsWithIcon;
