import React, { useEffect, useState } from "react";
import "./style.scss";
import DISCOVERY_IMG from "../../assets/Images/PodModel/steps/discovery.svg";
import SETUP_IMG from "../../assets/Images/PodModel/steps/setup.svg";
import LAUNCH_IMG from "../../assets/Images/PodModel/steps/launch.png";
import GROWTH_IMG from "../../assets/Images/PodModel/steps/growth.svg";

function StepsHoverCard() {
  const [step, setStep] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setStep((prevStep) => (prevStep % 4) + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getFilledLineWidth = () => {
    switch (step) {
      case 1:
        return "0%";
      case 2:
        return "33%";
      case 3:
        return "65%";
      case 4:
        return "96%";
      default:
        return "0%";
    }
  };
  const steps = [0, 1, 2, 3];

  const data = [
    {
      imgUrl: DISCOVERY_IMG,
      title: "DISCOVERY",
      description:
        "We tailor a customised solution based on your needs and provide a comprehensive roadmap for your project.",
    },
    {
      imgUrl: SETUP_IMG,
      title: "SETUP",
      description:
        "Our proven recruitment model helps us handpick top-tier talent for your team, while we take care of setting up your office space, equipment, and IT infrastructure.",
    },
    {
      imgUrl: LAUNCH_IMG,
      title: "LAUNCH",
      description:
        "We seamlessly onboard your new team members and ensure everything is primed for a successful start to your project.",
    },
    {
      imgUrl: GROWTH_IMG,
      title: "GROWTH",
      description:
        "We remain dedicated to keeping your team motivated and aligned, facilitating seamless scalability to meet your evolving needs.",
    },
  ];
  return (
    <div className="steps-hc-wrap">
      <div className="horizontal-progress-bar mt-70">
        <div className="horizontal-line-wrap">
          <div className="horizontal-line"></div>
          <div
            className="horizontal-filled-line"
            style={{ width: getFilledLineWidth() }}
          ></div>
        </div>
        <div className="horizontal-step-wrap">
          {steps.map((s, index) => (
            <div
              key={index}
              className={`horizontal-step ${
                step >= s ? "horizontal-filled" : ""
              }`}
            >
              0{s + 1}
            </div>
          ))}
        </div>
      </div>
      <div className="steps-hc-container">
        {data.map((item, index) => (
          <div key={index} className="steps-hc-item">
            <div className="steps-hc-item-content">
              <div className="steps-hc-img">
                <img src={item.imgUrl} alt="" />
              </div>
              <div className="steps-hc-title">{item.title}</div>
              <div className="steps-hc-description">{item.description}</div>
            </div>
            {/* <div className="steps-hc-item-overlay" /> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default StepsHoverCard;
